<template>
  <div class="">
    <topbar :title="woDeJiFen"></topbar>
    <p class="score">
      <span>233</span>
      <span>{{ jiFen }}</span>
    </p>
    <p class="scoreTime">
      <!-- <span>積分到期日:</span> -->
      <span>{{ jiFenDaoQiRi }}: 28/11/2019</span>
    </p>
    <div class="tab">
      <p @click="changeTab(1)" :class="{ active: tabActive == 1 }">
        <span>{{ yiHuoDe }}</span>
        <i></i>
      </p>
      <p @click="changeTab(2)" :class="{ active: tabActive == 2 }">
        <span>{{ yiShiYong }}</span>
        <i></i>
      </p>
    </div>
    <div class="scoreList" v-for="(item, index) in scoreList" :key="index">
      <div class="listLeft">
        <p>{{ yiWanChengDingDan }}</p>
        <p>28/11/2019 14:38</p>
      </div>
      <div class="listRight">+30</div>
    </div>
  </div>
</template>
<script>
import language from "../../assets/js/language";
import Topbar from "../../components/Topbar.vue";
export default {
  name: "Score",
  data() {
    return {
      tabActive: 1,
      scoreList: [1, 2],
      lanType: this.$store.state.language,
      // 靜態文字
      woDeJiFen: "",
      jiFen: "",
      jiFenDaoQiRi: "",
      yiHuoDe: "",
      yiShiYong: "",
      yiWanChengDingDan: "",
      jiFenDuiHan: "",
    };
  },
  methods: {
    changeTab(type) {
      this.tabActive = type;
    },
    setLanguage() {
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.woDeJiFen = language.woDeJiFen[type];
      this.jiFen = language.jiFen[type];
      this.jiFenDaoQiRi = language.jiFenDaoQiRi[type];
      this.yiHuoDe = language.yiHuoDe[type];
      this.yiShiYong = language.yiShiYong[type];
      this.yiWanChengDingDan = language.yiWanChengDingDan[type];
      this.jiFenDuiHan = language.jiFenDuiHan[type];
    },
  },
  created() {
    this.setLanguage();
  },
  components: {
    Topbar,
  },
};
</script>
<style scoped>
.score {
  width: 100%;
  text-align: left;
  height: 0.37rem;
  font-size: 0.26rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.37rem;
  color: #e4022b;
  padding: 0 0.16rem;
  box-sizing: border-box;
  display: flex;
  margin: 0.16rem 0 0.04rem;
}
.score span:last-child {
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
  margin-left: 0.02rem;
}
.scoreTime {
  height: 0.2re;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #474747;
  text-align: left;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.tab {
  width: 100%;
  height: 0.44rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.4rem;
}
.tab p {
  height: 100%;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
  position: relative;
}
.tab p i {
  position: absolute;
  bottom: 0.06rem;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 2px;
}
.tab p.active {
  color: #e4022b;
}
.tab p.active i {
  background-color: #e4022b;
}
.scoreList {
  width: 100%;
  height: 0.74rem;
  padding: 0.16rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listLeft {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #1f2123;
  text-align: left;
}
.listLeft p:last-child {
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
  margin-top: 0.04rem;
}
.listRight {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  color: #e4022b;
}
</style>